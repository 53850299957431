import { createSlice } from '@reduxjs/toolkit';

export const cartList = createSlice({
  name: 'cartList',
  initialState: {
    data: [],
  },

  reducers: {
    setCartList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCartList } = cartList.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getCartList = (state) => state.cartList.data;

export default cartList.reducer;
