import { createSlice } from '@reduxjs/toolkit';

export const paymentUpdate = createSlice({
  name: 'paymentUpdate',
  initialState: {
    data: {},
  },

  reducers: {
    setPaymentUpdate: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setPaymentUpdate } = paymentUpdate.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getPaymentUpdate = (state) => state.paymentUpdate.data;

export default paymentUpdate.reducer;
