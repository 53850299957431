import axios from 'axios';
import { Service } from 'axios-middleware';
import { navigate } from 'gatsby';
import { getDispatchData } from 'utils/_dispatch.js';
import { updateAuth } from '../utils/_auth.js';
import {
  ERROR_CODES,
  HTTP_METHOD,
  CODE_TIMEOUT,
  API_RESPONSE,
} from './_const.js';
import {
  PATH_GUEST,
  PATH_NO_NETWORK,
  PATH_HOME,
  PATH_REFRESH,
} from '../utils/constants/paths';
import { LOGOUT_TYPE } from 'utils/constants/logoutType';
import _ from 'lodash';

export default class Axios {
  constructor({
    baseURL = '',
    qcAuth = '',
    ocaToken = '',
    ccAuth = '',
    timeout = 0,
    recallOn = false,
    recallTimes = 0,
    headers = {},
    shouldReturnCode = true,
    refreshToken = '',
  }) {
    this._axios = axios.create();
    this._baseURL = baseURL || '';
    this._timeout = timeout ? timeout * 1000 : 0;
    this._qcAuth = qcAuth || '';
    this._ocaToken = ocaToken || '';
    this._ccAuth = ccAuth || '';
    this._recallOn = recallOn || false;
    this._recallTimes = recallTimes || 0;
    this._headers = headers;
    this._shouldReturnCode = shouldReturnCode;
    this._refreshToken = refreshToken || '';
    this._lockApi = false;

    this._init();
  }

  _init() {
    // if (isAuthenticated()) {
    this._axios = axios.create({
      baseURL: this._baseURL,
      timeout: this._timeout,
      headers: Object.assign(
        {},
        {
          'Content-Type': 'application/json',
          'OCA-Token': `${this._ocaToken}`,
          'qc-Auth': `${this._qcAuth}`,
          'CC-Auth': `${this._ccAuth}`,
          //apikey: process.env.GATSBY_API_KEY, //`6TCt992TWkDZD7gIlnALdFIFAJacvvwcmj5vvGryVRacWpin`,
          webMode: 'true',
        },
        this._headers
      ),
    });
  }

  // Alvin remarks: 變數鎖
  async _api(apiMethod, url, parameters = {}, options = {}, isAction) {
    const service = new Service(this._axios);
    service.register({
      onRequest: (config) => {
        // console.log('onRequest', config);
        return config;
      },
      onSync: (promise) => {
        // console.log('onSync');
        return promise;
      },
      onResponse: (response) => {
        // console.log('onResponse');
        return response;
      },
    });

    const isGet = apiMethod === HTTP_METHOD.GET;
    const isPost = apiMethod === HTTP_METHOD.POST;
    const isDelete = apiMethod === HTTP_METHOD.DELETE;
    const isPatch = apiMethod === HTTP_METHOD.PATCH;
    const isPut = apiMethod === HTTP_METHOD.PUT;
    const typeError = { message: 'error: api method invalid' };
    if (
      apiMethod !== HTTP_METHOD.GET &&
      apiMethod !== HTTP_METHOD.POST &&
      apiMethod !== HTTP_METHOD.DELETE &&
      apiMethod !== HTTP_METHOD.PATCH &&
      apiMethod !== HTTP_METHOD.PUT
    )
      throw typeError;

    let response;
    let requestTimes = 0;
    const requestTimesLimit = this._recallOn ? this._recallTimes : 0;
    let callAxios;
    let hadCalled;
    let isError = false;

    while (
      ((!response || response.code === CODE_TIMEOUT) &&
        requestTimes < requestTimesLimit) ||
      (!this._recallOn && !hadCalled)
    ) {
      callAxios = undefined;
      if (isGet) {
        callAxios = this._axios.get(url, parameters);
      }
      if (isPost) {
        callAxios = this._axios.post(url, parameters, options);
      }
      if (isDelete) {
        callAxios = this._axios.delete(url, parameters, options);
      }
      if (isPatch) {
        callAxios = this._axios.patch(url, parameters, options);
      }
      if (isPut) {
        callAxios = this._axios.put(url, parameters, options);
      }

      response = await callAxios
        .then((axiosRespose) => {
          if (
            !axiosRespose.data ||
            (this._shouldReturnCode && !axiosRespose.data.code) ||
            ERROR_CODES.includes(axiosRespose.data.code)
          )
            throw axiosRespose;

          isError = false;

          return axiosRespose.data;
        })
        .catch((error) => {
          isError = true;

          //沒網路就跳轉到沒網路頁面
          if (!navigator.onLine) {
            navigate(PATH_NO_NETWORK, { replace: true });
            return;
          }
          // return;
        });

      //如果發生440且api目前還沒鎖住，就先去refresh token
      if (response.code === API_RESPONSE.SessionExpired) {
        console.log('開始refresh session', url);

        if (isAction) {
          this._ccAuth = null;
          this._axios.defaults.headers['qc-Auth'] = `${this._qcAuth}`;
          const responseRefresh = await this._axios
            .get('v1/qc-site/Api-refreshSession', parameters, options)
            .then((axiosRespose) => {
              return axiosRespose.data;
            })
            .catch((error) => {
              isError = true;
              if (!navigator.onLine) {
                navigate(PATH_NO_NETWORK, { replace: true });
                return;
              }
            });
          if (responseRefresh.code === '200') {
            this._ccAuth = responseRefresh.data.cookie;
            this._axios.defaults.headers['CC-Auth'] = `${this._ccAuth}`;
            this._lockApi = false;
            updateAuth({ ccAuth: this._ccAuth });
            continue;
          }
        } else {
          // 如果頁面已經在refresh，就不再重複refresh
          if (window.location.pathname !== PATH_REFRESH) {
            const target = window.location.pathname + window.location.search;
            navigate(PATH_REFRESH, { state: { prevPath: target } });
          }
        }

        return;
      }

      requestTimes++;
      hadCalled = true;
    }

    if (response.code === API_RESPONSE.Unauthorized) {
      console.log('Unauthorized');
      const dispatchData = getDispatchData();
      const storeId = _.get(dispatchData, 'storeId', '');
      if (storeId === '') {
        localStorage.setItem('doLogout', LOGOUT_TYPE.LogoutClearAll);
        setTimeout(() => {
          // 如果在PATH_GUEST，就進行reload
          if (window.location.pathname === PATH_GUEST) {
            window.location.reload();
          } else {
            navigate(PATH_GUEST, { replace: true });
          }
        }, 400);
      } else {
        localStorage.setItem('doLogout', LOGOUT_TYPE.LogoutClearPart);
        setTimeout(() => {
          // 如果在PATH_HOME，就進行reload
          if (window.location.pathname === PATH_HOME) {
            window.location.reload();
          } else {
            navigate(PATH_HOME, { replace: true });
          }
        }, 400);
      }
    }

    if (isError) throw response;

    return response;
  }

  /**
   * Get data from server
   * @param url target path
   * @param params request params
   */
  get(url, parameters, options, isAction = false) {
    options = {
      ...options,
      params: parameters,
    };
    return this._api(HTTP_METHOD.GET, url, options, {}, isAction);
  }

  /**
   * Post data to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  post(url, parameters, options, isAction = false) {
    return this._api(HTTP_METHOD.POST, url, parameters, options, isAction);
  }

  /**
   * Delete date to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  delete(url, parameters, options, isAction = false) {
    return this._api(HTTP_METHOD.DELETE, url, parameters, options, isAction);
  }

  /**
   * Patch data to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  patch(url, parameters, options, isAction = false) {
    return this._api(HTTP_METHOD.PATCH, url, parameters, options, isAction);
  }

  /**
   * Put data to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  put(url, parameters, options, isAction = false) {
    return this._api(HTTP_METHOD.PUT, url, parameters, options, isAction);
  }

  /**
   * Update the token of API instance
   * @param token the new token to update
   */
  updateToken({ qcAuth, ocaToken, ccAuth, refreshToken }) {
    if (qcAuth != null) {
      this._qcAuth = qcAuth;
      this._axios.defaults.headers['qc-Auth'] = `${qcAuth}`;
    }

    if (ocaToken != null) {
      this._ocaToken = ocaToken;
      this._axios.defaults.headers['OCA-Token'] = `${ocaToken}`;
    }

    if (ccAuth != null) {
      this._ccAuth = ccAuth;
      this._axios.defaults.headers['CC-Auth'] = `${ccAuth}`;
    }

    if (refreshToken != null) {
      this._refreshToken = refreshToken;
    }
  }
}
