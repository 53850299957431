import { createSlice } from '@reduxjs/toolkit';

export const hkDiscountPrice = createSlice({
  name: 'hkDiscountPrice',
  initialState: {
    data: null,
  },

  reducers: {
    setHkDiscountPrice: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setHkDiscountPrice } = hkDiscountPrice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getHkDiscountPrice = (state) => state.hkDiscountPrice.data;

export default hkDiscountPrice.reducer;
