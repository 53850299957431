/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './src/styles/global.css';

export { default as wrapRootElement } from './wrap-with-provider';

const guestRoute = '/guest';
export const onRouteUpdate = ({ location, replace }) => {
  //未登入轉導
  // if (!isAuthenticated() && location.pathname !== '/cart') {
  //   navigate('/cart');
  // }
  // //已登入轉導
  // if (isAuthenticated() && location.pathname === '/cart') {
  //   navigate('/');
  // }
};

// import React from 'react';
// import { useAPI } from 'utils/api';
// export const wrapPageElement = ({ element, props }) => {
//   const api = useAPI();
//   React.useEffect(() => {
//     const param = {
//       customer_no: '',
//     };

//     api
//       .getInfo(param)
//       .then((res) => {
//         console.log('wrapPageElement getInfo res', res);
//         store.dispatch(actions.setInfo(res));
//       })
//       .catch((error) => {
//         console.log('setShippingMethod exception =', error);
//       });
//   }, []);

//   return <div>{React.cloneElement(element, props)}</div>;
// };
