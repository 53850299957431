import { createSlice } from '@reduxjs/toolkit';

export const localCache = createSlice({
  name: 'localCache',
  initialState: {
    data: {},
  },

  reducers: {
    addLocalCache: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.data[action.payload.key] = action.payload.data;
      }
    },
  },
});

export const { addLocalCache } = localCache.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getLocalCache = (state) => {
  return state.localCache.data;
};

export default localCache.reducer;
