import { createSlice } from '@reduxjs/toolkit';

export const additionItemShow = createSlice({
  name: 'additionItemShow',
  initialState: {
    data: 0,
  },

  reducers: {
    setAdditionItemShow: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setAdditionItemShow } = additionItemShow.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getAdditionItemShow = (state) => state.additionItemShow.data;

export default additionItemShow.reducer;
