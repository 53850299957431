import { createSlice } from '@reduxjs/toolkit';

export const userInfo = createSlice({
  name: 'userInfo',
  initialState: {
    data: {},
  },

  reducers: {
    setUserInfo: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setUserInfo } = userInfo.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getUserInfo = (state) => state.userInfo.data;

export default userInfo.reducer;
