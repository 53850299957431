import { createSlice } from '@reduxjs/toolkit';

export const recommendData = createSlice({
  name: 'recommendData',
  initialState: {
    data: {},
  },

  reducers: {
    setRecommendData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRecommendData } = recommendData.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getRecommendData = (state) => state.recommendData.data;

export default recommendData.reducer;
