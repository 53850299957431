const useProvideAlert = (setData) => {
  const showAlert = ({
    icon = true,
    onLeft,
    onRight,
    show = true,
    title,
    txtRight,
    txtLeft,
    option,
  }) => {
    setData({
      icon,
      onLeft,
      onRight,
      show,
      title,
      txtRight,
      txtLeft,
      option,
    });
  };

  const dismissAlert = () =>
    setData({
      icon: undefined,
      onLeft: undefined,
      onRight: undefined,
      show: false,
      title: undefined,
      txtRight: undefined,
      txtLeft: undefined,
      option: undefined,
    });

  const methods = { showAlert, dismissAlert };

  if (typeof window !== 'undefined') {
    window.alert = (title, confirm = () => {}) => {
      showAlert({
        title,
        txtRight: '確定',
        onLeft: () => {
          dismissAlert();
        },
        onRight: () => {
          dismissAlert();
          confirm();
        },
      });
    };

    window.confirm = (title, txtRight, txtLeft, option = {}) => {
      return new Promise((resolve, reject) => {
        showAlert({
          title,
          option,
          txtRight: txtRight ?? '確定',
          txtLeft: txtLeft ?? '取消',
          onLeft: () => {
            dismissAlert();
            reject();
          },
          onRight: () => {
            dismissAlert();
            resolve();
          },
        });
      });
    };

    window.popup = (title) => {
      showAlert({
        title,
      });
      setTimeout(() => {
        dismissAlert();
      }, 1000);
    };
  }
  return methods;
};

export default useProvideAlert;
