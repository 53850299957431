import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const setCartProductCountList = createAsyncThunk(
  'cartProductCountList/setCrtProductCountList',
  async (productList, { dispatch }) => {
    // 在此處執行您的異步操作
    // 異步操作完成後，根據需求發起 dispatch 或回調
    // 在這個例子中，假設您已經使用 redux-actions 套件創建了 setCartProductCountList action
    await dispatch(
      cartProductCountList.actions.setCartProductCountList(productList)
    );
  }
);

export const cartProductCountList = createSlice({
  name: 'cartProductCountList',
  initialState: {
    data: [],
  },
  reducers: {
    setCartProductCountList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCartProductCountList: setCartProductCountListAction } =
  cartProductCountList.actions;

export const getCartProductCountList = (state) =>
  state.cartProductCountList.data;

export default cartProductCountList.reducer;
