import { createSlice } from '@reduxjs/toolkit';

export const info = createSlice({
  name: 'info',
  initialState: {
    data: {},
  },

  reducers: {
    setInfo: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setInfo } = info.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getInfo = (state) => state.info.data;

export default info.reducer;
