export const API_RESPONSE = {
  SUCCESS: '200', // 成功

  NORMAL_ERROR: '100', //一般性錯誤，由前端呼叫，可歸咎於前端引起的錯誤，例如:參數錯誤、格式錯誤、呼叫位置不存在…等錯誤
  AUTH_ERROR: '300', //認證以及權限錯誤，在OMS系統在認證以及權限上發生的錯誤
  INTERNAL_ERROR: '400', //OMS系統錯誤/例外錯誤，例如:DB錯誤、計算錯誤、被例外catch到的錯誤
  INTERNAL_CONNECT_ERROR: '500', //在串接內部系統時發生的錯誤
  EXTERNAL_CONNECT_ERROR: '600', //在串接外部系統時發生的錯誤
  TRADE_ERROR: '700',
  STORE_CLOSE: '503', //店休
  COUPON_EMAIL_COMBINE: '428', // 折扣碼email沒綁定
  Unauthorized: '401',
  SessionExpired: '440',
  EXCLUDE_ZIP: '204',
};

// ERROR_CODES is a collection of constants that store all the code represent error
export const ERROR_CODES = [API_RESPONSE.INTERNAL_SERVER_ERROR];

// HTTP_STATUS is a collection of constants that store all the http status code
export const HTTP_STATUS = {
  OK: 200,
  NOT_FOUND: 404,
};

/** HTTP_METHOD is a collection of constants that store all the http methods */
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  PATCH: 'PATCH',
  PUT: 'PUT',
};

/** CODE_TIMEOUT is a constant that represent the error of api timeout */
export const CODE_TIMEOUT = 'ECONNABORTED';

/** UNKNOWN_ERROR is a constant that represent any unknown error */
export const UNKNOWN_ERROR = 'UNKNOWN_ERROR';

export const API_PREFIX = 'v1/qc-site';
