import React from 'react';
import { Provider } from 'react-redux';
import { SkeletonTheme } from 'react-loading-skeleton';
import store, { actions, getters } from 'redux/store';

import { ProvideAPI } from 'utils/api';
import { ProvideCallback } from 'utils/callback';
import { ProvideMount } from 'utils/mount';
import { ProvideAlert } from 'utils/alert';
import { ProvideLoading } from 'utils/loading';
import { skeletonBaseColor } from 'utils/colors';

import 'react-loading-skeleton/dist/skeleton.css';

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore();
  return (
    // <Provider store={store}>
    //   <ProvideAPI>{element}</ProvideAPI>
    // </Provider>
    <Provider store={store}>
      <ProvideAPI>
        <ProvideCallback>
          <ProvideMount>
            <ProvideLoading>
              <ProvideAlert>
                <SkeletonTheme baseColor={skeletonBaseColor}>
                  {element.props.children}
                </SkeletonTheme>
              </ProvideAlert>
            </ProvideLoading>
          </ProvideMount>
        </ProvideCallback>
      </ProvideAPI>
    </Provider>
  );
};
