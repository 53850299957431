import { createSlice } from '@reduxjs/toolkit';

export const cartReload = createSlice({
  name: 'cartReload',
  initialState: {
    data: false,
  },

  reducers: {
    setCartReload: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCartReload } = cartReload.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getCartReload = (state) => state.cartReload.data;

export default cartReload.reducer;
