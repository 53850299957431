import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, setUserId } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';

const debug_mode = process.env.GATSBY_USE_ENV === 'prod' ? false : true;

const checkOS = () => {
  const userAgent = navigator.userAgent;
  if (/android/i.test(userAgent)) {
    return 'Android';
  }
  if (/ipad|iphone|ipod/i.test(userAgent)) {
    return 'iOS';
  }
  return 'unknown';
};

const firebaseConfig =
  process.env.GATSBY_USE_ENV === 'prod'
    ? {
        apiKey: 'AIzaSyCJK6xVfWigq_9npXLnU6pX2yYw-Et__-I',
        authDomain: 'jsp-web-prod.firebaseapp.com',
        projectId: 'jsp-web-prod',
        storageBucket: 'jsp-web-prod.appspot.com',
        messagingSenderId: '1084531852717',
        appId: '1:1084531852717:web:8b584c5e96e08abc6e0d24',
        measurementId: 'G-TQWYRXPREQ',
      }
    : process.env.GATSBY_USE_ENV === 'uat'
    ? {
        apiKey: 'AIzaSyAjelNcCWMXfPej_gyWeZtPoTgK0QV-WPc',
        authDomain: 'jsp-web-uat.firebaseapp.com',
        projectId: 'jsp-web-uat',
        storageBucket: 'jsp-web-uat.appspot.com',
        messagingSenderId: '124579945867',
        appId: '1:124579945867:web:00f375784fe1f7d609238e',
        measurementId: 'G-537VQG4QZ1',
      }
    : process.env.GATSBY_USE_ENV === 'preprod'
    ? {
        apiKey: 'AIzaSyAjelNcCWMXfPej_gyWeZtPoTgK0QV-WPc',
        authDomain: 'jsp-web-uat.firebaseapp.com',
        projectId: 'jsp-web-uat',
        storageBucket: 'jsp-web-uat.appspot.com',
        messagingSenderId: '124579945867',
        appId: '1:124579945867:web:00f375784fe1f7d609238e',
        measurementId: 'G-537VQG4QZ1',
      }
    : {
        apiKey: 'AIzaSyAjelNcCWMXfPej_gyWeZtPoTgK0QV-WPc',
        authDomain: 'jsp-web-uat.firebaseapp.com',
        projectId: 'jsp-web-uat',
        storageBucket: 'jsp-web-uat.appspot.com',
        messagingSenderId: '124579945867',
        appId: '1:124579945867:web:00f375784fe1f7d609238e',
        measurementId: 'G-537VQG4QZ1',
      };

let instance;

const initSDK = () => {
  if (typeof window !== 'undefined') {
    if (instance) {
      return instance;
    }
    instance = initializeApp(firebaseConfig);
    getAnalytics(instance);
    getPerformance(instance);

    return instance;
  }
};

export { checkOS, initSDK };
