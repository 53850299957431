import { createSlice } from '@reduxjs/toolkit';

export const bonusPoint = createSlice({
  name: 'bonusPoint',
  initialState: {
    data: {},
  },

  reducers: {
    setBonusPoint: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setBonusPoint } = bonusPoint.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getBonusPoint = (state) => state.bonusPoint.data;

export default bonusPoint.reducer;
