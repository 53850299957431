import { createSlice } from '@reduxjs/toolkit';

export const nearByStores = createSlice({
  name: 'nearByStores',
  initialState: {
    data: [],
  },

  reducers: {
    setNearByStores: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setNearByStores } = nearByStores.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getNearByStores = (state) => {
  return state.nearByStores.data;
};

export default nearByStores.reducer;
