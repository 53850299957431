export const PATH_HOME = '/';
export const PATH_GUEST = '/guest';
export const PATH_ASSISTANCE = '/assistance';
export const PATH_CART = '/cart';
export const PATH_CATEGORY_ALL = '/category/all';
export const PATH_CATEGORY = '/category';
export const PATH_LOGIN = '/login';
export const PATH_MAINTENANCE = '/maintenance';
export const PATH_MEMBER_DELETE = '/member/delete';
export const PATH_MEMBER_EDIT = '/member/edit';
export const PATH_NO_NETWORK = '/nonetwork';
export const PATH_ORDER_INFO = '/order/info';
export const PATH_ORDER = '/order';
export const PATH_ORDER_QA = '/order/qa';
export const PATH_PAYMENT_FAIL = '/payment/fail';
export const PATH_PAYMENT_RESULT = '/payment/result';
export const PATH_PRIVACY_POLICY = '/privacy-policy';
export const PATH_PRODUCT = '/product';
export const PATH_RECEIVER = '/receiver';
export const PATH_REFUND_CANCEL = '/refund/cancel';
export const PATH_REFUND = '/refund';
export const PATH_RETURN_POLICY = '/return-policy';
export const PATH_SEARCH = '/search';
export const PATH_SHOPPING_INFORMATION = '/shopping-information';
export const PATH_SIGN_UP = '/sign-up';
export const PATH_TERMS = '/terms';
export const PATH_JKOPAY = '/jkopay';
export const PATH_FAQ =
  'https://online.carrefour.com.tw/zh/faq/?type=CarrefourFast%20FAQ';
export const PATH_STORES = 'https://www.carrefour.com.tw/stores/';
export const PATH_APPLE_STORE =
  'https://apps.apple.com/tw/app/%E5%AE%B6%E9%80%9F%E9%85%8D/id1614596715';
export const PATH_GOOGLE_PLAY =
  'https://play.google.com/store/apps/details?id=com.carrefour.qcommerceapp&hl=zh_TW&gl=US';
export const PATH_ADDITION = '/addition';
export const PATH_RECEIVER_EDIT = '/receiver/edit';
export const PATH_MY_COUPON = '/my-coupon';
export const PATH_COUPON = '/coupon';
export const PATH_RECOMMEND = '/recommend';
export const PATH_REFRESH = '/refresh';
