import { createSlice } from '@reduxjs/toolkit';

export const addressDetail = createSlice({
  name: 'addressDetail',
  initialState: {
    data: {},
  },

  reducers: {
    setAddressDetail: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setAddressDetail } = addressDetail.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getAddressDetail = (state) => {
  return state.addressDetail.data;
};

export default addressDetail.reducer;
