const getDispatchData = () => {
  if (typeof window !== 'undefined' && localStorage) {
    const strDispatch = localStorage.getItem('DispatchData');
    if (!strDispatch) {
      return null;
    }
    return JSON.parse(strDispatch);
  }
  return null;
};

const updateDispatchData = (key, value) => {
  if (typeof window !== 'undefined' && localStorage) {
    const dispatchData = getDispatchData();
    if (!dispatchData) {
      const param = {
        ...dispatchData,
        [key]: value,
      };
      localStorage.setItem('DispatchData', JSON.stringify(param));
    } else {
      dispatchData[key] = value;
      localStorage.setItem('DispatchData', JSON.stringify(dispatchData));
    }
  }
};

const initDispatchData = (param) => {
  if (typeof window !== 'undefined' && localStorage) {
    localStorage.setItem('DispatchData', JSON.stringify(param));
  }
};

const clearDispatchData = () => {
  if (typeof window !== 'undefined' && localStorage) {
    localStorage.removeItem('DispatchData');
  }
};

export {
  getDispatchData,
  updateDispatchData,
  initDispatchData,
  clearDispatchData,
};
