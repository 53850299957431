import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import useProvideMount from './_useProvideMount';
import { useSelector } from 'react-redux';
import { getters } from '../../redux/store';
import CcAuth from 'components/CcAuth';

const mountContext = createContext();

export const ProvideMount = ({ children }) => {
  const mount = useProvideMount();
  // const isReady = useSelector((state) => getters.isReady(state));
  // const ccAuthIsReady = useSelector((state) => getters.getInfoIsReady(state));

  return (
    <mountContext.Provider value={mount}>{children}</mountContext.Provider>
  );
  // return ccAuthIsReady ? (
  //   <mountContext.Provider value={mount}>{children}</mountContext.Provider>
  // ) : (
  //   <div></div>
  // );
};

ProvideMount.propTypes = {
  children: PropTypes.node,
};
