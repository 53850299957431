import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';
import store, { actions, getters } from 'redux/store';
import _ from 'lodash';

import { useCallbacks } from 'utils/callback';
import { useAPI } from 'utils/api';
import { useLoading } from 'utils/loading';
import { getDispatchData } from 'utils/_dispatch.js';
import {
  isAuthenticated,
  updateAuth,
  clearAuth,
  getAuth,
} from 'utils/_auth.js';

const CcAuth = ({ onFinished }) => {
  const api = useAPI();
  const loading = useLoading();
  const expireAt = useRef(1800); //多久去refresh一次

  useEffect(() => {
    loading.show();
    if (isAuthenticated()) {
      const currentTimestamp = Date.now();
      const currentTimestampInSeconds = Math.floor(currentTimestamp / 1000);
      const ccAuthExpiresAt = localStorage.getItem('ccAuthExpiresAt');
      if (ccAuthExpiresAt !== null) {
        //現在時間 - 之前的時間 < 1小時的話 就不用再去refresh
        if (currentTimestampInSeconds - ccAuthExpiresAt < expireAt.current) {
          loading.dismiss();
          if (onFinished) onFinished();
          return;
        }
      }
      api
        .getCookie()
        .then((res) => {
          console.log('getCookie res', res);
          updateAuth({ ccAuth: _.get(res, 'cookie', '') });
          const { ccAuth } = getAuth();
          api.updateToken({ ccAuth });
          localStorage.setItem('ccAuthExpiresAt', currentTimestampInSeconds);
          store.dispatch(actions.setCcAuthIsReady(true));
          loading.dismiss();
          if (onFinished) {
            onFinished();
          }
        })
        .catch((error) => {
          loading.dismiss();
          console.log('cookie exception =', error);
        });
      // api
      //   .getInfo({ passLocationInfo: 'true', passShippingInfo: 'true' })
      //   .then((res) => {
      //     console.log('CcAuth getInfo res', res);
      //     store.dispatch(actions.setCcAuthIsReady(true));
      //     store.dispatch(actions.setInfo(res));
      //     localStorage.setItem('ccAuthExpiresAt', currentTimestampInSeconds);
      //     loading.dismiss();
      //     if (onFinished) onFinished();
      //   })
      //   .catch((error) => {
      //     loading.dismiss();
      //     console.log('getInfo exception =', error);
      //   });
    } else {
      store.dispatch(actions.setCcAuthIsReady(true));
      loading.dismiss();
      if (onFinished) onFinished();
    }
  }, []);

  return <></>;
};
export default CcAuth;
