import { createSlice } from '@reduxjs/toolkit';

export const creditInfo = createSlice({
  name: 'creditInfo',
  initialState: {
    data: [],
    methods: {},
    newCards: [],
  },

  reducers: {
    setCreditInfo: (state, action) => {
      state.data = action.payload;
    },
    setPaymentMethods: (state, action) => {
      state.methods = action.payload;
    },
    setNewCards: (state, action) => {
      state.newCards = action.payload;
    },
  },
});

export const { setCreditInfo, setPaymentMethods, setNewCards } =
  creditInfo.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getCreditInfo = (state) => state.creditInfo.data;
export const getPaymentMethods = (state) => state.creditInfo.methods;
export const getNewCards = (state) => state.creditInfo.newCards;

export default creditInfo.reducer;
