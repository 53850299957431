import React, { createContext, useContext, useState } from 'react';

const callbackContext = createContext();

export const ProvideCallback = ({ children }) => {
  const [callbacks, setCallbacks] = useState({});

  const setCallback = (item) => {
    Object.keys(item).forEach((key) => {
      const value = item[key];
      setCallbacks((prevState) => ({ ...prevState, [key]: value }));
    });
  };

  const getCallback = (key) => {
    return callbacks[key];
  };

  const value = {
    setCallback,
    getCallback,
  };

  return (
    <callbackContext.Provider value={value}>
      {children}
    </callbackContext.Provider>
  );
};

export const useCallbacks = () => {
  return useContext(callbackContext);
  // const context = useContext(CallbackContext);
  // if (context === undefined) {
  //   throw new Error('useCallbacks must be used within a CallbackProvider');
  // }
  // return context;
};
