import { createSlice } from '@reduxjs/toolkit';

export const keepalive = createSlice({
  name: 'keepalive',
  initialState: {
    data: {},
  },

  reducers: {
    addKeepalive: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        state.data[action.payload.key] = action.payload.data;
      }
    },
    removeKeepalive: (state, action) => {
      if (action.payload !== undefined && action.payload !== null) {
        delete state.data[action.payload.key];
      }
    },
    cleanKeepalive: (state, action) => {
      state.data = {};
    },
  },
});

export const { addKeepalive, removeKeepalive, cleanKeepalive } =
  keepalive.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getKeepalive = (state) => {
  return state.keepalive.data;
};

export default keepalive.reducer;
