// 目前規則：alert > loading > drawer > modal > header
// 如果要改以上順序的話，請討論

export const header = 10000;
export const modalMask = 50000;
export const drawerMask = 60000;
export const loading = 80000;
export const alert = 90000;

export const modalAssistance = modalMask + 1000;
export const modalAddress = modalMask + 2000;
export const modalArrangeTime = modalMask + 3000;

export const drawerSearch = drawerMask + 1000;
export const drawerMember = drawerMask + 2000;
