import React, { useContext, createContext, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { loading as zIndexLoading } from 'utils/zIndex';

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
`;

const SpinnerOuter = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  background-color: transparent;
  border-radius: 50%;
  z-index: ${zIndexLoading + 1};

  .mask,
  .fill {
    width: 150px;
    height: 150px;
    position: absolute;
    border-radius: 50%;
  }

  .mask {
    clip: rect(0px, 150px, 150px, 75px);
  }

  .mask .fill {
    clip: rect(0px, 75px, 150px, 0px);
    background-color: #30bbd0;
  }

  .mask.full,
  .fill {
    animation: ${spin} infinite 2s;
    transform: rotate(360deg);
  }
`;

const SpinnerInner = styled.div`
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  font-weight: 700;
  color: #214a4c;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Mask = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: ${zIndexLoading};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* 或者您想要的高度 */
`;

const LoadingWrapper = styled.div`
  position: relative;
`;

const LoadingIcon = styled.div`
  width: 150px;
  height: 150px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
`;

const LoadingText = styled.div`
  font-size: 24px;
  font-weight: 700;
  color: #214a4c;
  text-align: center;
  line-height: 35px;
`;

const loadingContext = createContext();

export const ProvideLoading = ({ children }) => {
  const [_globalLoadingParamter, _setGlobalLoadingParamter] = useState({
    color: '#5FD2DA',
    loading: false,
    size: 150,
    cssOverride: {
      borderWidth: 10,
    },
    arialabel: 'Loading Spinner',
    datatestid: 'loader',
  });

  // const dialog = useProvideLoading(_setGlobalLoadingParamter);
  const show = () => {
    _setGlobalLoadingParamter({
      color: '#5FD2DA',
      loading: true,
      size: 150,
      cssOverride: {
        borderWidth: 10,
      },
      arialabel: 'Loading Spinner',
      datatestid: 'loader',
    });
  };

  const dismiss = () => {
    _setGlobalLoadingParamter({
      color: '#5FD2DA',
      loading: false,
      size: 150,
      cssOverride: {
        borderWidth: 10,
      },
      arialabel: 'Loading Spinner',
      datatestid: 'loader',
    });
  };

  const value = {
    show,
    dismiss,
  };

  // useEffect(() => {
  //   if (_globalLoadingParamter.loading) {
  //     document.querySelector('html, body').style.overflowY = 'hidden';
  //   } else {
  //     document.querySelector('html, body').style.overflowY = 'visible';
  //   }
  //   return () => {
  //     document.querySelector('html, body').style.overflowY = 'visible';
  //   };
  // }, [_globalLoadingParamter.loading]);

  return (
    <loadingContext.Provider value={value}>
      {_globalLoadingParamter.loading && (
        <Mask>
          <Container>
            <LoadingWrapper>
              <SpinnerOuter>
                <div className="mask full">
                  <div className="fill"></div>
                </div>
                <div className="mask">
                  <div className="fill"></div>
                </div>
                <SpinnerInner>家速配</SpinnerInner>
              </SpinnerOuter>
              <LoadingIcon />
            </LoadingWrapper>
          </Container>
        </Mask>
      )}
      {children}
    </loadingContext.Provider>
  );
};

export const useLoading = () => {
  return useContext(loadingContext);
};
