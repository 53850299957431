// import tokenReducer, { setToken } from './_token';
import nearByStoresReducer, {
  setNearByStores,
  getNearByStores,
} from './_nearByStores';
import addressReducer, { setAddress, getAddress } from './_address';
import mountDataReducer, { isReady, setIsReady } from './_mount';

import cartListReducer, { getCartList, setCartList } from './_cartList';
import refoundListReducer, {
  getRefoundList,
  setRefoundList,
} from './_refoundList';
import orderDetailReducer, {
  setOrderDetail,
  getOrderDetail,
} from './_orderDetail';
import viewCartReducer, { setViewCart, getViewCart } from './_gaViewCart';
import refundDetailReducer, {
  setRefundDetail,
  getRefundDetail,
} from './_refundDetail';
import storeTimeSlotsReducer, {
  setStoreTimeSlots,
  getStoreTimeSlots,
} from './_storeTimeSlots.js';
import userInfoReducer, { setUserInfo, getUserInfo } from './_userInfo.js';
import homePageOneReducer, {
  setHomePageOne,
  getHomePageOne,
} from './_homepageOne.js';
import homePageTwoReducer, {
  setHomePageTwo,
  getHomePageTwo,
} from './_homepageTwo.js';

import paymentReducer, { setPayment, getPayment } from './_payment.js';
import bonusPointReducer, { setBonusPoint, getBonusPoint } from './_bonusPoint';
import creditInfoReducer, {
  setCreditInfo,
  getCreditInfo,
  setPaymentMethods,
  getPaymentMethods,
  getNewCards,
  setNewCards,
} from './_creditInfo';

import localCacheReducer, { addLocalCache, getLocalCache } from './_localCache';

import keepaliveReducer, {
  addKeepalive,
  removeKeepalive,
  cleanKeepalive,
  getKeepalive,
} from './_keepalive';

import paramsReducer, { setParams, getParams } from './_params.js';
import recycleBagReducer, {
  setRecycleBag,
  getRecycleBag,
} from './_recycleBag.js';
import cartPriceReducer, { setCartPrice, getCartPrice } from './_cartPrice.js';
import infoReducer, { setInfo, getInfo } from './_info.js';
import qtyStatusReducer, { setQtyStatus, getQtyStatus } from './_qtyStatus.js';
import authIsReadyReducer, {
  setAuthIsReady,
  getAuthIsReady,
} from './_authIsReady.js';
import updateHeaderReducer, {
  setUpdateHeader,
  getUpdateHeader,
} from './_updateHeader.js';

import categoryListReducer, {
  setCategoryList,
  getCategoryList,
} from './_categoryList.js';

import shippingModelReducer, {
  setShippingModel,
  getShippingModel,
} from './_shippingModel.js';
import cartReloadReducer, {
  setCartReload,
  getCartReload,
} from './_cartReload.js';
import onModalAddressReducer, {
  setOnModalAddress,
  getOnModalAddress,
} from './_onModalAddress.js';

import changeShippingModelReducer, {
  setChangeShippingModel,
  getChangeShippingModel,
} from './_changeShippingModel.js';

import latLngReducer, { setLatLng, getLatLng } from './_latLng.js';

import hkDiscountPriceReducer, {
  setHkDiscountPrice,
  getHkDiscountPrice,
} from './_hkDiscountPrice.js';

import ccAuthIsReadyReducer, {
  setCcAuthIsReady,
  getCcAuthIsReady,
} from './_ccAuthIsReady.js';

import addressDetailReadyReducer, {
  setAddressDetail,
  getAddressDetail,
} from './_addressDetail.js';

import appIsCheckReducer, {
  setAppIsCheck,
  getAppIsCheck,
} from './_appIsCheck.js';

import deliveryTimeTextReducer, {
  setDeliveryTimeText,
  getDeliveryTimeText,
} from './_deliveryTimeText.js';

import cartProductCountListReducer, {
  setCartProductCountList,
  getCartProductCountList,
} from './_cartProductCountList.js';

import cartItemCountReducer, {
  setCartItemCount,
  getCartItemCount,
} from './_cartItemCount.js';
import paymentUpdateReducer, {
  setPaymentUpdate,
  getPaymentUpdate,
} from './_paymentUpdate.js';

import shippingInfoReducer, {
  setShippingInfo,
  getShippingInfo,
} from './_shippingInfo.js';

import additionItemsReducer, {
  setAdditionItems,
  getAdditionItems,
} from './_additionItems.js';

import additionItemShowReducer, {
  setAdditionItemShow,
  getAdditionItemShow,
} from './_additionItemShow.js';

import cartAdditionItemsReducer, {
  setCartAdditionItems,
  getCartAdditionItems,
} from './_cartAdditionItems.js';

import couponListReducer, {
  setCouponList,
  getCouponList,
} from './_couponList.js';

import myCouponListReducer, {
  setMyCouponList,
  getMyCouponList,
} from './_myCouponList.js';

import couponErrorMsgReducer, {
  setCouponErrorMsg,
  getCouponErrorMsg,
} from './_couponErrorMsg.js';

import recommendDataReducer, {
  setRecommendData,
  getRecommendData,
} from './_recommendData.js';

import recommendItemsReducer, {
  setRecommendItems,
  getRecommendItems,
} from './_recommendItems.js';

export const sliceActions = {
  setNearByStores,
  setCartList,
  setAddress,
  setRefoundList,
  setOrderDetail,
  setRefundDetail,
  setStoreTimeSlots,
  setPayment,
  setBonusPoint,
  setCreditInfo,
  setPaymentMethods,
  setUserInfo,
  setNewCards,
  setViewCart,
  addLocalCache,
  addKeepalive,
  removeKeepalive,
  cleanKeepalive,
  setHomePageOne,
  setHomePageTwo,
  setParams,
  setRecycleBag,
  setCartPrice,
  setInfo,
  setQtyStatus,
  setAuthIsReady,
  setIsReady,
  setUpdateHeader,
  setCategoryList,
  setShippingModel,
  setCartReload,
  setOnModalAddress,
  setChangeShippingModel,
  setHkDiscountPrice,
  setLatLng,
  setCcAuthIsReady,
  setAddressDetail,
  setAppIsCheck,
  setDeliveryTimeText,
  setCartProductCountList,
  setCartItemCount,
  setPaymentUpdate,
  setShippingInfo,
  setAdditionItems,
  setAdditionItemShow,
  setCartAdditionItems,
  setCouponList,
  setMyCouponList,
  setCouponErrorMsg,
  setRecommendData,
  setRecommendItems,
};

export const sliceGetters = {
  getNearByStores,
  isReady,
  getCartList,
  getAddress,
  getRefoundList,
  getOrderDetail,
  getStoreTimeSlots,
  getPayment,
  getBonusPoint,
  getRefundDetail,
  getCreditInfo,
  getPaymentMethods,
  getUserInfo,
  getNewCards,
  getViewCart,
  getLocalCache,
  getKeepalive,
  getHomePageOne,
  getHomePageTwo,
  getParams,
  getRecycleBag,
  getCartPrice,
  getInfo,
  getQtyStatus,
  getAuthIsReady,
  getUpdateHeader,
  getCategoryList,
  getShippingModel,
  getCartReload,
  getOnModalAddress,
  getChangeShippingModel,
  getLatLng,
  getHkDiscountPrice,
  getCcAuthIsReady,
  getAddressDetail,
  getAppIsCheck,
  getDeliveryTimeText,
  getCartProductCountList,
  getCartItemCount,
  getShippingInfo,
  getPaymentUpdate,
  getAdditionItems,
  getCartAdditionItems,
  getAdditionItemShow,
  getCouponList,
  getMyCouponList,
  getCouponErrorMsg,
  getRecommendData,
  getRecommendItems,
};

export default {
  mountData: mountDataReducer,
  nearByStores: nearByStoresReducer,
  cartList: cartListReducer,
  refoundList: refoundListReducer,
  address: addressReducer,
  orderDetail: orderDetailReducer,
  storeTimeSlots: storeTimeSlotsReducer,
  payment: paymentReducer,
  bonuxPoint: bonusPointReducer,
  refundDetail: refundDetailReducer,
  creditInfo: creditInfoReducer,
  userInfo: userInfoReducer,
  viewCart: viewCartReducer,
  localCache: localCacheReducer,
  keepalive: keepaliveReducer,
  homePageOne: homePageOneReducer,
  homePageTwo: homePageTwoReducer,
  params: paramsReducer,
  recycleBag: recycleBagReducer,
  cartPrice: cartPriceReducer,
  info: infoReducer,
  qtyStatus: qtyStatusReducer,
  authIsReady: authIsReadyReducer,
  updateHeader: updateHeaderReducer,
  categoryList: categoryListReducer,
  shippingModel: shippingModelReducer,
  cartReload: cartReloadReducer,
  changeShippingModel: changeShippingModelReducer,
  onModalAddress: onModalAddressReducer,
  hkDiscountPrice: hkDiscountPriceReducer,
  latLng: latLngReducer,
  ccAuthIsReady: ccAuthIsReadyReducer,
  addressDetail: addressDetailReadyReducer,
  appIsCheck: appIsCheckReducer,
  deliveryTimeText: deliveryTimeTextReducer,
  cartProductCountList: cartProductCountListReducer,
  cartItemCount: cartItemCountReducer,
  shippingInfo: shippingInfoReducer,
  paymentUpdate: paymentUpdateReducer,
  cartAdditionItems: cartAdditionItemsReducer,
  additionItems: additionItemsReducer,
  additionItemShow: additionItemShowReducer,
  couponList: couponListReducer,
  myCouponList: myCouponListReducer,
  couponErrorMsg: couponErrorMsgReducer,
  recommendData: recommendDataReducer,
  recommendItems: recommendItemsReducer,
};
