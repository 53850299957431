import React from 'react';
import styled, { css } from 'styled-components';

const Container = styled.div`
  width: 268px;
  height: 52px;
  background-color: #5fd2da;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;

  ${(props) =>
    props.ghost &&
    css`
      background-color: #fff;
      color: #5fd2da;
      border: 1px solid #5fd2da;
    `}

  ${(props) =>
    props.disabled &&
    css`
      background-color: #c4c4c4;
    `}
`;

const Button = (props) => {
  const { children, className } = props;

  return (
    <Container {...props} className={className}>
      {children}
    </Container>
  );
};

export default Button;
