import { createSlice } from '@reduxjs/toolkit';

export const payment = createSlice({
  name: 'payment',
  initialState: {
    data: {},
  },

  reducers: {
    setPayment: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setPayment } = payment.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getPayment = (state) => state.payment.data;

export default payment.reducer;
