import { createSlice } from '@reduxjs/toolkit';

export const mountData = createSlice({
  name: 'mountData',
  initialState: {
    isReady: false,
  },

  reducers: {
    setIsReady: (state, action) => {
      state.isReady = action.payload;
    },
  },
});

export const { setIsReady } = mountData.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const isReady = (state) => {
  if (state.mountData.isReady) {
    return true;
  }

  return false;
};

export default mountData.reducer;
