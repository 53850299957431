import { createSlice } from '@reduxjs/toolkit';

export const refundDetail = createSlice({
  name: 'refundDetail',
  initialState: {
    data: {},
  },

  reducers: {
    setRefundDetail: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRefundDetail } = refundDetail.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getRefundDetail = (state) => {
  return state.refundDetail.data;
};

export default refundDetail.reducer;
