import React, { useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';

import { modalMask as zIndexModalMask } from 'utils/zIndex';

import IconClose from 'images/close.svg';

const Container = styled.div`
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  position: relative;
`;

const Mask = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${zIndexModalMask};
`;

const ContentWrapper = styled.div`
  position: fixed;
  z-index: ${zIndexModalMask + 1};
  width: ${(props) => props.width}px;

  ${(props) =>
    props.centered &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
`;

const Content = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
`;

const BtnClose = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  cursor: pointer;
  z-index: 500;
`;

const Modal = (props) => {
  const {
    centered = true,
    children,
    className,
    closable = false,
    onClose,
    open,
    width,
  } = props;
  const currentTop = useRef();

  useEffect(() => {
    if (open) {
      currentTop.current = window.scrollY;
      document.documentElement.style.position = 'fixed';
      document.documentElement.style.top = `-${currentTop.current}px`;
      document.documentElement.style.width = '100%';
      document.documentElement.style.overflowY = 'scroll';
    }
    //因modal關閉時不會跑進來這useEffect，所以用return方式處理
    return () => {
      document.documentElement.style.position = 'static';
      document.documentElement.style.top = '';
      window.scrollTo(0, currentTop.current);
    };
  }, [open]);

  return (
    <Container className={className} open={open}>
      <Mask className="mask" onClick={onClose} />
      <ContentWrapper
        className="content-wrapper"
        centered={centered}
        open={open}
        width={width}
      >
        {closable && (
          <BtnClose className="btn-close" src={IconClose} onClick={onClose} />
        )}
        <Content className="content">{children}</Content>
      </ContentWrapper>
    </Container>
  );
};

export default Modal;
