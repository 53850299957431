import { createSlice } from '@reduxjs/toolkit';

export const ccAuthIsReady = createSlice({
  name: 'ccAuthIsReady',
  initialState: {
    data: false,
  },

  reducers: {
    setCcAuthIsReady: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setCcAuthIsReady } = ccAuthIsReady.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getCcAuthIsReady = (state) => state.ccAuthIsReady.data;

export default ccAuthIsReady.reducer;
