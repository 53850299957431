import React from 'react';
import styled from 'styled-components';

import Modal from 'components/UI/Modal';
import Button from 'components/UI/Button';

import { alert as zIndexAlert } from 'utils/zIndex';

import IconCheckMark from 'images/check-mark-1.svg';

const StyledModal = styled(Modal)`
  .mask {
    z-index: ${zIndexAlert};
  }

  .content-wrapper {
    z-index: ${zIndexAlert + 1};
  }
`;

const StyledBtn = styled(Button)`
  flex: 1;
  height: 52px;
`;

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px 0;
`;

const Message = styled.div`
  font-size: 18px;
  font-weight: 700;
  color: #000000;
`;

const BtnGroup = styled.div`
  display: flex;
  gap: 0 10px;
  width: 100%;
`;

const Alert = (props) => {
  const {
    cancelText,
    title,
    okText = '確認',
    onCancel,
    onOk,
    open,
    icon,
    option,
  } = props;

  return (
    <StyledModal centered open={open} width={332}>
      <Container>
        <Message style={option?.title ?? {}}>{title}</Message>
        {icon && <img src={IconCheckMark} />}
        {(onCancel || onOk) && (
          <BtnGroup>
            {onCancel && cancelText !== undefined && (
              <StyledBtn disabled onClick={onCancel} style={option?.left ?? {}}>
                {cancelText}
              </StyledBtn>
            )}
            {onOk && (
              <StyledBtn onClick={onOk} style={option?.right ?? {}}>
                {okText}
              </StyledBtn>
            )}
          </BtnGroup>
        )}
      </Container>
    </StyledModal>
  );
};

export default Alert;
