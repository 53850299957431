import React, { useState } from 'react';
import { useAPI } from '../api';
import store, { actions, getters } from '../../redux/store';
import WebFn, { callAppFunc } from '../jsbridge/index';
import { useSelector } from 'react-redux';
import { encode, decode } from 'js-base64';
import { isAuthenticated, updateAuth, clearAuth, getAuth } from '../_auth.js';
import { initSDK } from '../_firebase.js';

const useProvideMount = () => {
  const api = useAPI();
  // // if develop
  useState(() => {
    if (isAuthenticated()) {
      const { qcAuth, ocaToken, ccAuth, refreshToken } = getAuth();
      api.updateToken({ qcAuth, ocaToken, ccAuth, refreshToken });
    }
    if (typeof window !== 'undefined') {
      initSDK();
    }
  }, []);
};

export default useProvideMount;
