import { createSlice } from '@reduxjs/toolkit';

export const orderDetail = createSlice({
  name: 'orderDetail',
  initialState: {
    data: {},
  },

  reducers: {
    setOrderDetail: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setOrderDetail } = orderDetail.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getOrderDetail = (state) => {
  return state.orderDetail.data;
};

export default orderDetail.reducer;
