import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'components/UI/Alert';

import useProvideAlert from './_useProvideAlert';

const alertContext = createContext();

export const ProvideAlert = ({ children }) => {
  const [_globalAlertParamter, _setGlobalAlertParamter] = useState({
    show: false,
    title: undefined,
    icon: undefined,
    txtLeft: undefined,
    txtRight: undefined,
    onLeft: undefined,
    onRight: undefined,
    option: undefined,
  });
  const dialog = useProvideAlert(_setGlobalAlertParamter);

  return (
    <alertContext.Provider value={dialog}>
      {_globalAlertParamter.show && (
        <Alert
          cancelText={_globalAlertParamter.txtLeft}
          title={_globalAlertParamter.title}
          okText={_globalAlertParamter.txtRight}
          onCancel={_globalAlertParamter.onLeft}
          onOk={_globalAlertParamter.onRight}
          open={_globalAlertParamter.show}
          icon={_globalAlertParamter.icon}
          option={_globalAlertParamter.option}
        />
      )}
      {children}
    </alertContext.Provider>
  );
};

ProvideAlert.propTypes = {
  children: PropTypes.node,
};

export const useAlert = () => {
  return useContext(alertContext);
};
