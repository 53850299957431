import { createSlice } from '@reduxjs/toolkit';

export const refoundList = createSlice({
  name: 'refoundList',
  initialState: {
    data: [],
  },

  reducers: {
    setRefoundList: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setRefoundList } = refoundList.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getRefoundList = (state) => state.refoundList.data;

export default refoundList.reducer;
