import React, { useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import useProvideAPI from './_useProvideApi';

const apiContext = createContext();

export const ProvideAPI = ({ children }) => {
  const api = useProvideAPI();
  return <apiContext.Provider value={api}>{children}</apiContext.Provider>;
};

ProvideAPI.propTypes = {
  children: PropTypes.node,
};

export const useAPI = () => {
  return useContext(apiContext);
};
