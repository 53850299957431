import { createSlice } from '@reduxjs/toolkit';

export const params = createSlice({
  name: 'params',
  initialState: {
    data: {},
  },

  reducers: {
    setParams: (state, action) => {
      Object.keys(action.payload).forEach((key) => {
        const value = action.payload[key];
        state.data[key] = value;
      });
    },
  },
});

export const { setParams } = params.actions;

// Get callback function by key
// export const getCallback = (key) => (state) => state.callback[key];
export const getParams = (state) => {
  return state.params.data;
};

export default params.reducer;
