import Cookies from 'js-cookie';

const isAuthenticated = () => {
  // 檢查用戶是否已經登入
  // 返回 true 或 false
  if (typeof window !== 'undefined') {
    // const qcAuth = localStorage.getItem('QcAuth');
    // const ocaToken = localStorage.getItem('OcaToken');
    // const ccAuth = localStorage.getItem('CcAuth');
    // const refreshToken = localStorage.getItem('RefreshToken');
    const qcAuth = Cookies.get('QcAuth');
    const ocaToken = Cookies.get('OcaToken');
    const ccAuth = Cookies.get('CcAuth');
    const refreshToken = Cookies.get('RefreshToken');

    if (qcAuth && ocaToken && ccAuth && refreshToken) {
      return true;
    }
  }
  return false;
};

const updateAuth = ({ qcAuth, ocaToken, ccAuth, refreshToken }) => {
  // 更新用戶登入狀態
  // 返回 true 或 false
  if (typeof window !== 'undefined') {
    if (qcAuth) {
      // localStorage.setItem('QcAuth', qcAuth);
      Cookies.set('QcAuth', qcAuth, { expires: 999 });
    }
    if (ocaToken) {
      // localStorage.setItem('OcaToken', ocaToken);
      Cookies.set('OcaToken', ocaToken, { expires: 999 });
    }
    if (ccAuth) {
      // localStorage.setItem('CcAuth', ccAuth);
      Cookies.set('CcAuth', ccAuth, { expires: 999 });
    }
    if (refreshToken) {
      // localStorage.setItem('RefreshToken', refreshToken);
      Cookies.set('RefreshToken', refreshToken, { expires: 999 });
    }
  }
};

const clearAuth = () => {
  // 清除用戶登入狀態
  if (typeof window !== 'undefined') {
    // localStorage.removeItem('QcAuth');
    // localStorage.removeItem('OcaToken');
    // localStorage.removeItem('CcAuth');
    // localStorage.removeItem('RefreshToken');
    Cookies.remove('QcAuth');
    Cookies.remove('OcaToken');
    Cookies.remove('CcAuth');
    Cookies.remove('RefreshToken');
  }
};

const getAuth = () => {
  // 取得用戶登入狀態
  if (typeof window !== 'undefined') {
    // const qcAuth = localStorage.getItem('QcAuth');
    // const ocaToken = localStorage.getItem('OcaToken');
    // const ccAuth = localStorage.getItem('CcAuth');
    // const refreshToken = localStorage.getItem('RefreshToken');
    const qcAuth = Cookies.get('QcAuth');
    const ocaToken = Cookies.get('OcaToken');
    const ccAuth = Cookies.get('CcAuth');
    const refreshToken = Cookies.get('RefreshToken');
    return {
      qcAuth,
      ocaToken,
      ccAuth,
      refreshToken,
    };
  }

  return {};
};

export { isAuthenticated, updateAuth, clearAuth, getAuth };
